
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { getAdministratorRequest } from "@/sea/apis/AdministratorsService";
import { useStore } from "vuex";

export default defineComponent({
  name: "account-overview",
  components: {},
  setup() {
    const store = useStore();
    const userId = store.getters.currentUser?.id;

    const { administratorRef, getAdministrator } = getAdministratorRequest();
    const loadingRef = ref(false);

    const fetchAdministrator = async (id: number) => {
      loadingRef.value = true;
      await getAdministrator(id);
      loadingRef.value = false;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.account.tabOverview", [
        "sea.pages.account.title",
      ]);
      await fetchAdministrator(userId);
    });

    return {
      I18nUtils,
      administratorRef,
      loadingRef,
    };
  },
});
