import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header cursor-pointer" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3" }
const _hoisted_5 = {
  key: 0,
  class: "spinner-border spinner-border-sm align-middle ms-2 text-primary"
}
const _hoisted_6 = { class: "card-body p-9" }
const _hoisted_7 = { class: "row mb-7" }
const _hoisted_8 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_9 = { class: "col-lg-8" }
const _hoisted_10 = {
  key: 0,
  class: "fw-bolder fs-6 text-dark"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_13 = { class: "col-lg-8" }
const _hoisted_14 = {
  key: 0,
  class: "fw-bold fs-6 text-dark text-hover-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.I18nUtils.translate("sea.pages.account.secProfileDetails")), 1),
        (_ctx.loadingRef)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_router_link, {
        to: "/account/settings",
        class: "btn btn-primary align-self-center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.I18nUtils.translate("sea.pages.account.btnEditProfile")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.I18nUtils.translate("sea.pages.account.fieldUsername")), 1),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.administratorRef)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.administratorRef.username), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.I18nUtils.translate("sea.pages.account.fieldEmail")), 1),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.administratorRef)
            ? (_openBlock(), _createElementBlock("label", _hoisted_14, _toDisplayString(_ctx.administratorRef.email), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}